function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import getScrollBarSize from '../getScrollBarSize';
import setStyle from '../setStyle';
var locks = [];
var scrollingEffectClassName = 'ant-scrolling-effect';
var scrollingEffectClassNameReg = new RegExp("".concat(scrollingEffectClassName), 'g');
var uuid = 0; // https://github.com/ant-design/ant-design/issues/19340
// https://github.com/ant-design/ant-design/issues/19332

var cacheStyle = new Map();

var ScrollLocker = function ScrollLocker(options) {
  var _this = this;

  _classCallCheck(this, ScrollLocker);

  this.lock = function () {
    var _this$options2;

    // If lockTarget exist return
    if (locks.some(function (_ref) {
      var target = _ref.target;
      return target === _this.lockTarget;
    })) {
      return;
    } // If same container effect, return


    if (locks.some(function (_ref2) {
      var _this$options;

      var options = _ref2.options;
      return (options === null || options === void 0 ? void 0 : options.container) === ((_this$options = _this.options) === null || _this$options === void 0 ? void 0 : _this$options.container);
    })) {
      locks = [].concat(_toConsumableArray(locks), [{
        target: _this.lockTarget,
        options: _this.options
      }]);
      return;
    }

    var scrollBarSize = 0;

    if (window.innerWidth - document.documentElement.clientWidth > 0) {
      scrollBarSize = getScrollBarSize();
    }

    var container = ((_this$options2 = _this.options) === null || _this$options2 === void 0 ? void 0 : _this$options2.container) || document.body;
    var containerClassName = container.className;

    if (locks.filter(function (_ref3) {
      var _this$options3;

      var options = _ref3.options;
      return (options === null || options === void 0 ? void 0 : options.container) === ((_this$options3 = _this.options) === null || _this$options3 === void 0 ? void 0 : _this$options3.container);
    }).length === 0) {
      cacheStyle.set(container, setStyle({
        paddingRight: "".concat(scrollBarSize, "px"),
        overflow: 'hidden',
        overflowX: 'hidden',
        overflowY: 'hidden'
      }, {
        element: container
      }));
    } // https://github.com/ant-design/ant-design/issues/19729


    if (!scrollingEffectClassNameReg.test(containerClassName)) {
      var addClassName = "".concat(containerClassName, " ").concat(scrollingEffectClassName);
      container.className = addClassName.trim();
    }

    locks = [].concat(_toConsumableArray(locks), [{
      target: _this.lockTarget,
      options: _this.options
    }]);
  };

  this.unLock = function () {
    var _this$options4;

    var findLock = locks.find(function (_ref4) {
      var target = _ref4.target;
      return target === _this.lockTarget;
    });
    locks = locks.filter(function (_ref5) {
      var target = _ref5.target;
      return target !== _this.lockTarget;
    });

    if (!findLock || locks.some(function (_ref6) {
      var _findLock$options;

      var options = _ref6.options;
      return (options === null || options === void 0 ? void 0 : options.container) === ((_findLock$options = findLock.options) === null || _findLock$options === void 0 ? void 0 : _findLock$options.container);
    })) {
      return;
    } // Remove Effect


    var container = ((_this$options4 = _this.options) === null || _this$options4 === void 0 ? void 0 : _this$options4.container) || document.body;
    var containerClassName = container.className;
    if (!scrollingEffectClassNameReg.test(containerClassName)) return;
    setStyle(cacheStyle.get(container), {
      element: container
    });
    cacheStyle.delete(container);
    container.className = container.className.replace(scrollingEffectClassNameReg, '').trim();
  }; // eslint-disable-next-line no-plusplus


  this.lockTarget = uuid++;
  this.options = options;
};

export { ScrollLocker as default };