"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _Upload = _interopRequireDefault(require("./Upload"));

var _Dragger = _interopRequireDefault(require("./Dragger"));

_Upload["default"].Dragger = _Dragger["default"];
var _default = _Upload["default"];
exports["default"] = _default;