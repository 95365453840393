'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports['default'] = {
    confirmLabel: '确定',
    backspaceLabel: '退格',
    cancelKeyboardLabel: '收起键盘'
};
module.exports = exports['default'];