"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _grid = require("../grid");

var _default = _grid.Col;
exports["default"] = _default;