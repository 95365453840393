'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports['default'] = {
    WrapComponent: 'div',
    transitionName: 'am-slide-up',
    maskTransitionName: 'am-fade'
};
module.exports = exports['default'];