'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _reactDom = require('react-dom');

var ReactDOM = _interopRequireWildcard(_reactDom);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj['default'] = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var createPortal = ReactDOM.createPortal;

var Portal = function (_React$Component) {
    (0, _inherits3['default'])(Portal, _React$Component);

    function Portal(props) {
        (0, _classCallCheck3['default'])(this, Portal);

        var _this = (0, _possibleConstructorReturn3['default'])(this, (Portal.__proto__ || Object.getPrototypeOf(Portal)).call(this, props));

        _this.container = _this.props.getContainer();
        return _this;
    }

    (0, _createClass3['default'])(Portal, [{
        key: 'render',
        value: function render() {
            if (this.props.children) {
                return createPortal(this.props.children, this.container);
            }
            return null;
        }
    }]);
    return Portal;
}(React.Component);

exports['default'] = Portal;
module.exports = exports['default'];