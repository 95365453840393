"use strict";

exports.__esModule = true;
exports.registerEngine = registerEngine;
exports.getEngine = getEngine;
exports.createCanvas = createCanvas;

var _matrix = _interopRequireDefault(require("./util/matrix"));

exports.Matrix = _matrix["default"];

var _vector = _interopRequireDefault(require("./util/vector2"));

exports.Vector2 = _vector["default"];

var _engine = require("./engine");

exports.Canvas = _engine.Canvas;
exports.Group = _engine.Group;
exports.Shape = _engine.Shape;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var engines = {};

function registerEngine(name, engine) {
  engines[name] = engine;
}

function getEngine(name) {
  var G = engines[name];

  if (G) {
    return G;
  }

  return {
    Canvas: _engine.Canvas,
    Group: _engine.Group,
    Shape: _engine.Shape
  };
}

function createCanvas(cfg) {
  var renderer = cfg.renderer;
  var G = getEngine(renderer);
  return new G.Canvas(cfg);
}