"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends2 = require("babel-runtime/helpers/extends");

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require("babel-runtime/helpers/classCallCheck");

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require("babel-runtime/helpers/createClass");

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require("babel-runtime/helpers/possibleConstructorReturn");

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require("babel-runtime/helpers/inherits");

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj["default"] = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var __rest = undefined && undefined.__rest || function (s, e) {
    var t = {};
    for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
    }return t;
};

var Input = function (_React$Component) {
    (0, _inherits3["default"])(Input, _React$Component);

    function Input() {
        (0, _classCallCheck3["default"])(this, Input);

        var _this = (0, _possibleConstructorReturn3["default"])(this, (Input.__proto__ || Object.getPrototypeOf(Input)).apply(this, arguments));

        _this.onInputBlur = function (e) {
            var value = e.target.value;
            if (_this.props.onBlur) {
                _this.props.onBlur(value);
            }
        };
        _this.onInputFocus = function (e) {
            // here should have a value definition but none.
            var value = e.target.value;
            if (_this.props.onFocus) {
                _this.props.onFocus(value);
            }
        };
        _this.focus = function () {
            if (_this.inputRef) {
                _this.inputRef.focus();
            }
        };
        return _this;
    }

    (0, _createClass3["default"])(Input, [{
        key: "render",
        value: function render() {
            var _this2 = this;

            var _a = this.props,
                onBlur = _a.onBlur,
                onFocus = _a.onFocus,
                restProps = __rest(_a, ["onBlur", "onFocus"]);
            return React.createElement("input", (0, _extends3["default"])({ ref: function ref(el) {
                    return _this2.inputRef = el;
                }, onBlur: this.onInputBlur, onFocus: this.onInputFocus }, restProps));
        }
    }]);
    return Input;
}(React.Component);

exports["default"] = Input;
module.exports = exports["default"];