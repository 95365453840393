"use strict";

exports.__esModule = true;
exports["default"] = void 0;

var _shape = _interopRequireDefault(require("./shape"));

var _rect = _interopRequireDefault(require("./rect"));

var _image = _interopRequireDefault(require("./image"));

var _circle = _interopRequireDefault(require("./circle"));

var _line = _interopRequireDefault(require("./line"));

var _polygon = _interopRequireDefault(require("./polygon"));

var _polyline = _interopRequireDefault(require("./polyline"));

var _arc = _interopRequireDefault(require("./arc"));

var _sector = _interopRequireDefault(require("./sector"));

var _text = _interopRequireDefault(require("./text"));

var _custom = _interopRequireDefault(require("./custom"));

var _marker = _interopRequireDefault(require("./marker"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

_shape["default"].Rect = _rect["default"];
_shape["default"].Image = _image["default"];
_shape["default"].Circle = _circle["default"];
_shape["default"].Line = _line["default"];
_shape["default"].Polygon = _polygon["default"];
_shape["default"].Polyline = _polyline["default"];
_shape["default"].Arc = _arc["default"];
_shape["default"].Sector = _sector["default"];
_shape["default"].Text = _text["default"];
_shape["default"].Custom = _custom["default"];
_shape["default"].Marker = _marker["default"];
var _default = _shape["default"];
exports["default"] = _default;