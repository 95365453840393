'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports['default'] = {
    activateText: '松开立即刷新',
    deactivateText: '下拉可以刷新',
    finishText: '完成刷新'
};
module.exports = exports['default'];