"use strict";

exports.__esModule = true;
exports.generateScaleAria = generateScaleAria;
exports.generateCoordAria = generateCoordAria;

var _common = require("../../util/common");

var _global = require("../../global");

function generateScaleAria(scale) {
  var type = scale.type,
      values = scale.values;

  if (type === 'linear') {
    return (0, _common.substitute)(_global.lang.scale.linear, scale);
  }

  if (type === 'cat') {
    return (0, _common.substitute)(_global.lang.scale.cat, {
      values: values.slice(0, 10).join(' ')
    });
  }

  if (type === 'timeCat') {
    var start = scale.getText(values[0]);
    var end = scale.getText(values[values.length - 1]);
    return (0, _common.substitute)(_global.lang.scale.timeCat, {
      start: start,
      end: end
    });
  }

  return '';
}

function generateCoordAria(coord, xScale, yScale) {
  var type = coord.type;

  if (!_global.lang.coord[type]) {
    return '';
  }

  return (0, _common.substitute)(_global.lang.coord[type], {
    xLabel: generateScaleAria(xScale),
    yLabel: generateScaleAria(yScale)
  });
}