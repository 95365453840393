"use strict";

exports.__esModule = true;
exports.Color = exports.Size = exports.Shape = exports.Position = void 0;

var _position = _interopRequireDefault(require("./position"));

exports.Position = _position["default"];

var _shape = _interopRequireDefault(require("./shape"));

exports.Shape = _shape["default"];

var _size = _interopRequireDefault(require("./size"));

exports.Size = _size["default"];

var _color = _interopRequireDefault(require("./color"));

exports.Color = _color["default"];

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }