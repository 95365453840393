'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports['default'] = {
    okText: '确定',
    dismissText: '取消',
    extra: '请选择'
};
module.exports = exports['default'];