"use strict";

exports.__esModule = true;
exports["default"] = void 0;

var _dodge = _interopRequireDefault(require("@antv/adjust/lib/dodge"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = _dodge["default"];
exports["default"] = _default;