'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _propTypes = require('prop-types');

var PropTypes = _interopRequireWildcard(_propTypes);

var _rmcPullToRefresh = require('rmc-pull-to-refresh');

var _rmcPullToRefresh2 = _interopRequireDefault(_rmcPullToRefresh);

var _getLocale = require('../_util/getLocale');

var _icon = require('../icon');

var _icon2 = _interopRequireDefault(_icon);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj['default'] = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var PullToRefresh = function (_React$Component) {
    (0, _inherits3['default'])(PullToRefresh, _React$Component);

    function PullToRefresh() {
        (0, _classCallCheck3['default'])(this, PullToRefresh);
        return (0, _possibleConstructorReturn3['default'])(this, (PullToRefresh.__proto__ || Object.getPrototypeOf(PullToRefresh)).apply(this, arguments));
    }

    (0, _createClass3['default'])(PullToRefresh, [{
        key: 'render',
        value: function render() {
            // tslint:disable-next-line:variable-name
            var _getComponentLocale = (0, _getLocale.getComponentLocale)(this.props, this.context, 'PullToRefresh', function () {
                return require('./locale/zh_CN');
            }),
                activateText = _getComponentLocale.activateText,
                deactivateText = _getComponentLocale.deactivateText,
                finishText = _getComponentLocale.finishText;

            var ind = (0, _extends3['default'])({ activate: activateText, deactivate: deactivateText, release: React.createElement(_icon2['default'], { type: 'loading' }), finish: finishText }, this.props.indicator);
            return React.createElement(_rmcPullToRefresh2['default'], (0, _extends3['default'])({}, this.props, { indicator: ind }));
        }
    }]);
    return PullToRefresh;
}(React.Component);

exports['default'] = PullToRefresh;

PullToRefresh.defaultProps = {
    prefixCls: 'am-pull-to-refresh'
};
PullToRefresh.contextTypes = {
    antLocale: PropTypes.object
};
module.exports = exports['default'];