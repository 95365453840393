'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports['default'] = {
    year: '年',
    month: '月',
    day: '日',
    hour: '时',
    minute: '分',
    am: '上午',
    pm: '下午'
};
module.exports = exports['default'];