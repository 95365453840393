'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Brief = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _classnames5 = require('classnames');

var _classnames6 = _interopRequireDefault(_classnames5);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _rmcFeedback = require('rmc-feedback');

var _rmcFeedback2 = _interopRequireDefault(_rmcFeedback);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj['default'] = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var __rest = undefined && undefined.__rest || function (s, e) {
    var t = {};
    for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
    }return t;
};
/* tslint:disable:jsx-no-multiline-js */

var Brief = exports.Brief = function (_React$Component) {
    (0, _inherits3['default'])(Brief, _React$Component);

    function Brief() {
        (0, _classCallCheck3['default'])(this, Brief);
        return (0, _possibleConstructorReturn3['default'])(this, (Brief.__proto__ || Object.getPrototypeOf(Brief)).apply(this, arguments));
    }

    (0, _createClass3['default'])(Brief, [{
        key: 'render',
        value: function render() {
            return React.createElement(
                'div',
                { className: 'am-list-brief', style: this.props.style },
                this.props.children
            );
        }
    }]);
    return Brief;
}(React.Component);

var ListItem = function (_React$Component2) {
    (0, _inherits3['default'])(ListItem, _React$Component2);

    function ListItem(props) {
        (0, _classCallCheck3['default'])(this, ListItem);

        var _this2 = (0, _possibleConstructorReturn3['default'])(this, (ListItem.__proto__ || Object.getPrototypeOf(ListItem)).call(this, props));

        _this2.onClick = function (ev) {
            var _this2$props = _this2.props,
                onClick = _this2$props.onClick,
                platform = _this2$props.platform;

            var isAndroid = platform === 'android';
            if (!!onClick && isAndroid) {
                if (_this2.debounceTimeout) {
                    clearTimeout(_this2.debounceTimeout);
                    _this2.debounceTimeout = null;
                }
                var Item = ev.currentTarget;
                var RippleWidth = Math.max(Item.offsetHeight, Item.offsetWidth);
                var ClientRect = ev.currentTarget.getBoundingClientRect();
                var pointX = ev.clientX - ClientRect.left - Item.offsetWidth / 2;
                var pointY = ev.clientY - ClientRect.top - Item.offsetWidth / 2;
                var coverRippleStyle = {
                    width: RippleWidth + 'px',
                    height: RippleWidth + 'px',
                    left: pointX + 'px',
                    top: pointY + 'px'
                };
                _this2.setState({
                    coverRippleStyle: coverRippleStyle,
                    RippleClicked: true
                }, function () {
                    _this2.debounceTimeout = setTimeout(function () {
                        _this2.setState({
                            coverRippleStyle: { display: 'none' },
                            RippleClicked: false
                        });
                    }, 1000);
                });
            }
            if (onClick) {
                onClick(ev);
            }
        };
        _this2.state = {
            coverRippleStyle: { display: 'none' },
            RippleClicked: false
        };
        return _this2;
    }

    (0, _createClass3['default'])(ListItem, [{
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout);
                this.debounceTimeout = null;
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _classnames,
                _classnames3,
                _classnames4,
                _this3 = this;

            var _a = this.props,
                prefixCls = _a.prefixCls,
                className = _a.className,
                activeStyle = _a.activeStyle,
                error = _a.error,
                align = _a.align,
                wrap = _a.wrap,
                disabled = _a.disabled,
                children = _a.children,
                multipleLine = _a.multipleLine,
                thumb = _a.thumb,
                extra = _a.extra,
                arrow = _a.arrow,
                onClick = _a.onClick,
                restProps = __rest(_a, ["prefixCls", "className", "activeStyle", "error", "align", "wrap", "disabled", "children", "multipleLine", "thumb", "extra", "arrow", "onClick"]);var platform = restProps.platform,
                otherProps = __rest(restProps, ["platform"]);var _state = this.state,
                coverRippleStyle = _state.coverRippleStyle,
                RippleClicked = _state.RippleClicked;

            var wrapCls = (0, _classnames6['default'])(prefixCls + '-item', className, (_classnames = {}, (0, _defineProperty3['default'])(_classnames, prefixCls + '-item-disabled', disabled), (0, _defineProperty3['default'])(_classnames, prefixCls + '-item-error', error), (0, _defineProperty3['default'])(_classnames, prefixCls + '-item-top', align === 'top'), (0, _defineProperty3['default'])(_classnames, prefixCls + '-item-middle', align === 'middle'), (0, _defineProperty3['default'])(_classnames, prefixCls + '-item-bottom', align === 'bottom'), _classnames));
            var rippleCls = (0, _classnames6['default'])(prefixCls + '-ripple', (0, _defineProperty3['default'])({}, prefixCls + '-ripple-animate', RippleClicked));
            var lineCls = (0, _classnames6['default'])(prefixCls + '-line', (_classnames3 = {}, (0, _defineProperty3['default'])(_classnames3, prefixCls + '-line-multiple', multipleLine), (0, _defineProperty3['default'])(_classnames3, prefixCls + '-line-wrap', wrap), _classnames3));
            var arrowCls = (0, _classnames6['default'])(prefixCls + '-arrow', (_classnames4 = {}, (0, _defineProperty3['default'])(_classnames4, prefixCls + '-arrow-horizontal', arrow === 'horizontal'), (0, _defineProperty3['default'])(_classnames4, prefixCls + '-arrow-vertical', arrow === 'down' || arrow === 'up'), (0, _defineProperty3['default'])(_classnames4, prefixCls + '-arrow-vertical-up', arrow === 'up'), _classnames4));
            var content = React.createElement(
                'div',
                (0, _extends3['default'])({}, otherProps, { onClick: function onClick(ev) {
                        _this3.onClick(ev);
                    }, className: wrapCls }),
                thumb ? React.createElement(
                    'div',
                    { className: prefixCls + '-thumb' },
                    typeof thumb === 'string' ? React.createElement('img', { src: thumb }) : thumb
                ) : null,
                React.createElement(
                    'div',
                    { className: lineCls },
                    children !== undefined && React.createElement(
                        'div',
                        { className: prefixCls + '-content' },
                        children
                    ),
                    extra !== undefined && React.createElement(
                        'div',
                        { className: prefixCls + '-extra' },
                        extra
                    ),
                    arrow && React.createElement('div', { className: arrowCls, 'aria-hidden': 'true' })
                ),
                React.createElement('div', { style: coverRippleStyle, className: rippleCls })
            );
            var touchProps = {};
            Object.keys(otherProps).forEach(function (key) {
                if (/onTouch/i.test(key)) {
                    touchProps[key] = otherProps[key];
                    delete otherProps[key];
                }
            });
            return React.createElement(
                _rmcFeedback2['default'],
                (0, _extends3['default'])({}, touchProps, { disabled: disabled || !onClick, activeStyle: activeStyle, activeClassName: prefixCls + '-item-active' }),
                content
            );
        }
    }]);
    return ListItem;
}(React.Component);

ListItem.defaultProps = {
    prefixCls: 'am-list',
    align: 'middle',
    error: false,
    multipleLine: false,
    wrap: false,
    platform: 'ios'
};
ListItem.Brief = Brief;
exports['default'] = ListItem;