"use strict";

exports.__esModule = true;
exports.EVENT_REPAINT = exports.EVENT_CLEAR_INNER = exports.EVENT_CLEAR = exports.EVENT_AFTER_GEOM_DRAW = exports.EVENT_BEFORE_GEOM_DRAW = exports.EVENT_AFTER_GEOM_INIT = exports.EVENT_AFTER_SIZE_CHANGE = exports.EVENT_AFTER_DATA_CHANGE = exports.EVENT_BEFORE_DATA_CHANGE = exports.EVENT_AFTER_RENDER = exports.EVENT_BEFORE_RENDER = exports.EVENT_AFTER_INIT = void 0;
var EVENT_AFTER_INIT = 'afterinit';
exports.EVENT_AFTER_INIT = EVENT_AFTER_INIT;
var EVENT_BEFORE_RENDER = 'beforerender';
exports.EVENT_BEFORE_RENDER = EVENT_BEFORE_RENDER;
var EVENT_AFTER_RENDER = 'afterrender';
exports.EVENT_AFTER_RENDER = EVENT_AFTER_RENDER;
var EVENT_BEFORE_DATA_CHANGE = 'beforedatachange';
exports.EVENT_BEFORE_DATA_CHANGE = EVENT_BEFORE_DATA_CHANGE;
var EVENT_AFTER_DATA_CHANGE = 'afterdatachange';
exports.EVENT_AFTER_DATA_CHANGE = EVENT_AFTER_DATA_CHANGE;
var EVENT_AFTER_SIZE_CHANGE = '_aftersizechange';
exports.EVENT_AFTER_SIZE_CHANGE = EVENT_AFTER_SIZE_CHANGE;
var EVENT_AFTER_GEOM_INIT = '_aftergeominit';
exports.EVENT_AFTER_GEOM_INIT = EVENT_AFTER_GEOM_INIT;
var EVENT_BEFORE_GEOM_DRAW = 'beforegeomdraw';
exports.EVENT_BEFORE_GEOM_DRAW = EVENT_BEFORE_GEOM_DRAW;
var EVENT_AFTER_GEOM_DRAW = 'aftergeomdraw';
exports.EVENT_AFTER_GEOM_DRAW = EVENT_AFTER_GEOM_DRAW;
var EVENT_CLEAR = 'clear';
exports.EVENT_CLEAR = EVENT_CLEAR;
var EVENT_CLEAR_INNER = 'clearinner';
exports.EVENT_CLEAR_INNER = EVENT_CLEAR_INNER;
var EVENT_REPAINT = 'repaint';
exports.EVENT_REPAINT = EVENT_REPAINT;