'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _reactDom = require('react-dom');

var ReactDOM = _interopRequireWildcard(_reactDom);

var _class = require('../_util/class');

var _CustomKeyboard = require('./CustomKeyboard');

var _CustomKeyboard2 = _interopRequireDefault(_CustomKeyboard);

var _Portal = require('./Portal');

var _Portal2 = _interopRequireDefault(_Portal);

var _exenv = require('../_util/exenv');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj['default'] = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var customNumberKeyboard = null;
var IS_REACT_16 = !!ReactDOM.createPortal;
function getBodyScrollTop() {
    var el = document.scrollingElement || document.documentElement;
    return el && el.scrollTop || 0;
}
function setBodyScrollTop(scrollTop) {
    var el = document.scrollingElement || document.documentElement;
    el.scrollTop = scrollTop;
}

var NumberInput = function (_React$Component) {
    (0, _inherits3['default'])(NumberInput, _React$Component);

    function NumberInput(props) {
        (0, _classCallCheck3['default'])(this, NumberInput);

        var _this = (0, _possibleConstructorReturn3['default'])(this, (NumberInput.__proto__ || Object.getPrototypeOf(NumberInput)).call(this, props));

        _this.onChange = function (value) {
            if (!('value' in _this.props)) {
                _this.setState({ value: value.target.value });
            }
            _this.props.onChange(value);
        };
        _this.onConfirm = function (value) {
            _this.props.onVirtualKeyboardConfirm(value);
        };
        _this.addBlurListener = function () {
            document.addEventListener('click', _this.doBlur, false);
        };
        _this.removeBlurListener = function () {
            document.removeEventListener('click', _this.doBlur, false);
        };
        _this.saveRef = function (el) {
            if (IS_REACT_16 && el) {
                customNumberKeyboard = el;
            }
        };
        _this.doBlur = function (ev) {
            var value = _this.state.value;

            if (ev.target !== _this.inputRef) {
                _this.onInputBlur(value);
            }
        };
        _this.unLinkInput = function () {
            if (customNumberKeyboard && customNumberKeyboard.antmKeyboard && customNumberKeyboard.linkedInput && customNumberKeyboard.linkedInput === _this) {
                customNumberKeyboard.linkedInput = null;
                if (_this.props.autoAdjustHeight) {
                    _this.getContainer().style.height = '0';
                }
                (0, _class.addClass)(customNumberKeyboard.antmKeyboard, _this.props.keyboardPrefixCls + '-wrapper-hide');
            }
            // for unmount
            _this.removeBlurListener();
        };
        _this.onInputBlur = function (value) {
            if (IS_REACT_16) {
                _this.keyBoard = null;
            }
            var focus = _this.state.focus;

            if (focus) {
                _this.setState({
                    focus: false
                });
                _this.props.onBlur(value);
                setTimeout(function () {
                    _this.unLinkInput();
                }, 50);
            }
        };
        _this.onInputFocus = function () {
            var value = _this.state.value;

            _this.props.onFocus(value);
            _this.setState({
                focus: true
            }, function () {
                if (customNumberKeyboard) {
                    customNumberKeyboard.linkedInput = _this;
                    if (customNumberKeyboard.antmKeyboard) {
                        if (_this.props.autoAdjustHeight) {
                            var keyBoardHeight = customNumberKeyboard.antmKeyboard.offsetHeight;
                            _this.getContainer().style.height = keyBoardHeight + 'px';
                            if (_this.inputRef) {
                                var _this$inputRef$getBou = _this.inputRef.getBoundingClientRect(),
                                    bottom = _this$inputRef$getBou.bottom;

                                var clientHeight = window.innerHeight;
                                // 计算输入框距离视窗的底部距离
                                var distance = clientHeight - bottom;
                                if (distance < keyBoardHeight) {
                                    setBodyScrollTop(getBodyScrollTop() + keyBoardHeight - distance);
                                }
                            }
                        }
                        (0, _class.removeClass)(customNumberKeyboard.antmKeyboard, _this.props.keyboardPrefixCls + '-wrapper-hide');
                    }
                    customNumberKeyboard.confirmDisabled = value === '';
                    if (customNumberKeyboard.confirmKeyboardItem) {
                        if (value === '') {
                            (0, _class.addClass)(customNumberKeyboard.confirmKeyboardItem, _this.props.keyboardPrefixCls + '-item-disabled');
                        } else {
                            (0, _class.removeClass)(customNumberKeyboard.confirmKeyboardItem, _this.props.keyboardPrefixCls + '-item-disabled');
                        }
                    }
                }
            });
        };
        _this.onKeyboardClick = function (KeyboardItemValue) {
            var maxLength = _this.props.maxLength;
            var value = _this.state.value;
            // tslint:disable-next-line:no-this-assignment

            var onChange = _this.onChange;

            var valueAfterChange = void 0;
            // 删除键
            if (KeyboardItemValue === 'delete') {
                valueAfterChange = value.substring(0, value.length - 1);
                onChange({ target: { value: valueAfterChange } });
                // 确认键
            } else if (KeyboardItemValue === 'confirm') {
                valueAfterChange = value;
                onChange({ target: { value: valueAfterChange } });
                _this.onInputBlur(value);
                _this.onConfirm(value);
                // 收起键
            } else if (KeyboardItemValue === 'hide') {
                valueAfterChange = value;
                _this.onInputBlur(valueAfterChange);
            } else {
                if (maxLength !== undefined && +maxLength >= 0 && (value + KeyboardItemValue).length > maxLength) {
                    valueAfterChange = (value + KeyboardItemValue).substr(0, maxLength);
                    onChange({ target: { value: valueAfterChange } });
                } else {
                    valueAfterChange = value + KeyboardItemValue;
                    onChange({ target: { value: valueAfterChange } });
                }
            }
            if (customNumberKeyboard) {
                customNumberKeyboard.confirmDisabled = valueAfterChange === '';
                if (customNumberKeyboard.confirmKeyboardItem) {
                    if (valueAfterChange === '') {
                        (0, _class.addClass)(customNumberKeyboard.confirmKeyboardItem, _this.props.keyboardPrefixCls + '-item-disabled');
                    } else {
                        (0, _class.removeClass)(customNumberKeyboard.confirmKeyboardItem, _this.props.keyboardPrefixCls + '-item-disabled');
                    }
                }
            }
        };
        _this.onFakeInputClick = function () {
            _this.focus();
        };
        _this.focus = function () {
            // this focus may invocked by users page button click, so this click may trigger blurEventListener at the same time
            _this.renderCustomKeyboard();
            _this.removeBlurListener();
            var focus = _this.state.focus;

            if (!focus) {
                _this.onInputFocus();
            }
            setTimeout(function () {
                _this.addBlurListener();
            }, 50);
        };
        _this.state = {
            focus: false,
            value: props.value || ''
        };
        return _this;
    }

    (0, _createClass3['default'])(NumberInput, [{
        key: 'componentWillReceiveProps',
        value: function componentWillReceiveProps(nextProps) {
            if ('value' in nextProps) {
                this.setState({
                    value: nextProps.value
                });
            }
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            // focus:true unmount 不能触发 blur
            if (this.state.focus) {
                this.props.onBlur(this.state.value);
            }
            this.unLinkInput();
        }
    }, {
        key: 'getComponent',
        value: function getComponent() {
            var _props = this.props,
                confirmLabel = _props.confirmLabel,
                backspaceLabel = _props.backspaceLabel,
                cancelKeyboardLabel = _props.cancelKeyboardLabel,
                keyboardPrefixCls = _props.keyboardPrefixCls,
                moneyKeyboardWrapProps = _props.moneyKeyboardWrapProps,
                moneyKeyboardHeader = _props.moneyKeyboardHeader,
                disabledKeys = _props.disabledKeys;

            return React.createElement(_CustomKeyboard2['default'], { ref: this.saveRef, onClick: this.onKeyboardClick, prefixCls: keyboardPrefixCls, confirmLabel: confirmLabel, backspaceLabel: backspaceLabel, cancelKeyboardLabel: cancelKeyboardLabel, wrapProps: moneyKeyboardWrapProps, header: moneyKeyboardHeader, disabledKeys: disabledKeys });
        }
    }, {
        key: 'getContainer',
        value: function getContainer() {
            var keyboardPrefixCls = this.props.keyboardPrefixCls;

            var container = document.querySelector('#' + keyboardPrefixCls + '-container');
            if (!container) {
                container = document.createElement('div');
                container.setAttribute('id', keyboardPrefixCls + '-container');
                document.body.appendChild(container);
            }
            this.container = container;
            return this.container;
        }
    }, {
        key: 'renderCustomKeyboard',
        value: function renderCustomKeyboard() {
            var _this2 = this;

            if (IS_REACT_16) {
                this.keyBoard = React.createElement(
                    _Portal2['default'],
                    { getContainer: function getContainer() {
                            return _this2.getContainer();
                        } },
                    this.getComponent()
                );
            } else {
                customNumberKeyboard = ReactDOM.unstable_renderSubtreeIntoContainer(this, this.getComponent(), this.getContainer());
            }
        }
    }, {
        key: 'renderPortal',
        value: function renderPortal() {
            if (!IS_REACT_16 || !_exenv.canUseDOM) {
                return null;
            }
            return this.keyBoard;
        }
    }, {
        key: 'render',
        value: function render() {
            var _this3 = this;

            var _props2 = this.props,
                placeholder = _props2.placeholder,
                disabled = _props2.disabled,
                editable = _props2.editable,
                moneyKeyboardAlign = _props2.moneyKeyboardAlign;
            var _state = this.state,
                focus = _state.focus,
                value = _state.value;

            var preventKeyboard = disabled || !editable;
            var fakeInputCls = (0, _classnames2['default'])('fake-input', {
                focus: focus,
                'fake-input-disabled': disabled
            });
            var fakeInputContainerCls = (0, _classnames2['default'])('fake-input-container', {
                'fake-input-container-left': moneyKeyboardAlign === 'left'
            });
            return React.createElement(
                'div',
                { className: fakeInputContainerCls },
                value === '' &&
                // tslint:disable-next-line:jsx-no-multiline-js
                React.createElement(
                    'div',
                    { className: 'fake-input-placeholder' },
                    placeholder
                ),
                React.createElement(
                    'div',
                    { role: 'textbox', 'aria-label': value || placeholder, className: fakeInputCls, ref: function ref(el) {
                            return _this3.inputRef = el;
                        }, onClick: preventKeyboard ? function () {} : this.onFakeInputClick },
                    value
                ),
                this.renderPortal()
            );
        }
    }]);
    return NumberInput;
}(React.Component);

NumberInput.defaultProps = {
    onChange: function onChange() {},
    onFocus: function onFocus() {},
    onBlur: function onBlur() {},
    onVirtualKeyboardConfirm: function onVirtualKeyboardConfirm() {},
    placeholder: '',
    disabled: false,
    editable: true,
    prefixCls: 'am-input',
    keyboardPrefixCls: 'am-number-keyboard',
    autoAdjustHeight: false
};
exports['default'] = NumberInput;
module.exports = exports['default'];