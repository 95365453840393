'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.nonsense = undefined;

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _AbstractPicker2 = require('./AbstractPicker');

var _AbstractPicker3 = _interopRequireDefault(_AbstractPicker2);

var _propTypes = require('prop-types');

var PropTypes = _interopRequireWildcard(_propTypes);

var _popupProps = require('./popupProps');

var _popupProps2 = _interopRequireDefault(_popupProps);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj['default'] = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

// TODO:
// fix error TS4026:Public static property 'defaultProps' of exported class has or is using name 'React.ReactElement'
// fix error TS6133: 'React' is declared but its value is never read.
var nonsense = exports.nonsense = React.createElement('div', null);

var Picker = function (_AbstractPicker) {
    (0, _inherits3['default'])(Picker, _AbstractPicker);

    function Picker() {
        (0, _classCallCheck3['default'])(this, Picker);

        var _this = (0, _possibleConstructorReturn3['default'])(this, (Picker.__proto__ || Object.getPrototypeOf(Picker)).apply(this, arguments));

        _this.popupProps = _popupProps2['default'];
        return _this;
    }

    return Picker;
}(_AbstractPicker3['default']);

exports['default'] = Picker;

Picker.defaultProps = (0, _AbstractPicker2.getDefaultProps)();
Picker.contextTypes = {
    antLocale: PropTypes.object
};