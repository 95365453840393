'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports['default'] = {
    year: '',
    month: '',
    day: '',
    hour: '',
    minute: '',
    am: 'AM',
    pm: 'PM'
};
module.exports = exports['default'];