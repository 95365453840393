import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import * as React from 'react';
export default function usePreviewIndex(src, urls) {
  var _React$useState = React.useState(urls.indexOf(src)),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      index = _React$useState2[0],
      setIndex = _React$useState2[1];

  React.useEffect(function () {
    if (index !== urls.indexOf(src)) {
      setIndex(urls.indexOf(src));
    }
  }, [src]);
  return [index, setIndex];
}